.dashboard-item {
  &__link-custom {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #E41472;
    text-align: right;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.switcher__item--cnter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.operation-form {
  max-width: 594px;
}

.select--networks .select__drop {
  width: 247px;
}

.card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  gap: 16px;
}

.select--bigger {
  height: 60px;
}

.wallet-address {
  display: flex;
  align-items: center;
  gap: 16px;
  &__value {
    max-width: 100%;
    word-break: break-word;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: var(--text-color2);
    &--type2 {
      color: var(--text-color-main);
    }
  }
}

.deposit-address {
  display: flex;
  align-items: center;
  margin-top: 18px;
  gap: 40px;
  &__qr {
    display: flex;
    width: 135px;
    img, iframe {
      width: 100%
    }
  }
}

.button--type7 {
  background-color: #f3f3f3;
}

.louder-wrapper-custom  {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  min-height: 100%;
}

.louder-small-wrapper-custom  {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.switcher-wrapper {
  display: flex;
  justify-content: space-between;
}

.switcher--last {
  &:last-child {
    @media screen and (max-width: 635px) {
      flex-direction: column;
      max-width: 100%;
    }
  }
}

.switcher {
  &__item--custom {
    @media screen and (max-width: 635px) {
      min-height: 50px;
    }
  }
}

.td-hidden-name-upper {
  text-transform: uppercase;
}

.td-value-first-letter-up {
  text-transform: capitalize;
}

.td-value-flex-start {
  text-align: start;
}

.success-icon-ctnter-custom {
  text-align: center;
}

.order-option__value-custom {
  margin-left: 15px;
  word-break: break-all;
}

.switcher--smaller {
  width: 544px;
}