.popup-remove {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(37, 37, 46, 0.8);

  &__wrapper {
    max-width: 634px;
    max-height: 245px;
    height: 100%;
    width: 100%;
    left: 403px;
    top: 436px;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 40px 24px;
  }

  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #25252E;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #25252E;
  }

  &__btn-wrapper {
    display: flex;
    margin-top: 24px;
    gap: 16px;

    button {
      width: 100%;
    }
  }

  &__btn-cancel {
    background: #F3F3F3;
    border-radius: 14px;
    padding: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #25252E;
  }

  &__btn-remove {
    background: #E41472;
    border-radius: 14px;
    padding: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #FFFFFF;
  }
}

.popup-remove-custom {
  z-index: 4;
}