@import 'reset.scss';
@import 'ui-kit.scss';
@import 'ui-change.scss';
@import 'swiper.scss';

:root {
  --main-bg                : #FFF;
  --block-bg               : #FFF;
  --block-bg2              : #25252E;
  --block-bg3              : #F3F3F3;

  --button-bg2             : #F3F3F3;

  --main-color             : #E41472;
  --accent-color           : #1A6BD8;
  --accent-secondary-color : #3FDBB1;
  --accent-third-color     : #31BDAA;

  --text-color-main        : #25252E;
  --text-color2            : #9E9E9E;
  --text-color3            : #706D6D;
  --text-color-white       : #FFF;

  --border-color-main      : #D5D5D5;
  --border-color2          : #E6E6E6;

  --border-width           : 1px;
  --border-radius          : 4px;
  --bg-color               : #FFF;
}

* {
  -webkit-font-smoothing : antialiased;
}

body {
  background             : var(--main-bg);
  font-family            : 'Poppins', sans-serif;
  line-height            : 1.5;
  font-size              : 14px;
  font-weight            : 400;
  color                  : var(--text-color-main);
  -webkit-font-smoothing : antialiased;
}

button,
input,
label,
textarea {
  font-family : 'Poppins', sans-serif;
}

svg,
img {
  width     : auto;
  max-width : 100%;
}

.flex {
  display     : flex;
  align-items : flex-start;
  gap         : 12px;
}

.flex-m {
  align-items : center;
}

.container {
  max-width : 1310px;
  padding   : 0 15px;
  margin    : 0 auto;
}

.authorization-section {
  display : block;
  padding : 150px 0;

  @media screen and (max-width : 991px) {
    padding : 100px 0;
  }

  @media screen and (max-width : 991px) {
    padding : 40px 0;
  }
}

.authorization-box {
  max-width : 420px;
  width     : 100%;
  margin    : 0 auto;
}

.authorization-form,
.form {
  border-radius : 20px;
  background    : #FFF;
  border        : 1px solid var(--border-main);

  &__body {
    margin-left  : auto;
    margin-right : auto;
  }
}

.form {
  &__body {
    margin-top : 24px;
  }

  &__header {
    &--fixed {
      max-width    : 440px;
      margin-left  : auto;
      margin-right : auto;
    }
  }
}

.form-title {
  font-weight : 600;
  font-size   : 34px;
  line-height : 1.5;

  &--center {
    text-align : center;
  }

  @media screen and (max-width : 1199px) {
    font-size : 30px;
  }

  @media screen and (max-width : 991px) {
    font-size : 28px;
  }

  @media screen and (max-width : 767px) {
    font-size : 24px;
  }
}

.form-subtitle {
  margin-top  : 12px;
  color       : var(--text-color2);
  text-align  : center;
  font-weight : 400;
  font-size   : 18px;
  line-height : 1.78;

  @media screen and (max-width : 767px) {
    font-size : 16px;
  }
}

.form-text {
  margin-top  : 12px;
  font-weight : 500;
  line-height : 1.65;
  font-size   : 14px;
  color       : var(--text-color2);

  &--center {
    text-align : center;
  }

  &--fw-600 {
    font-weight : 600;
  }

  &--mt-0 {
    margin-top : 0;
  }
}

.form-group {
  margin-top : 16px;

  &--more-mt {
    margin-top : 24px;
  }
}

.form-footer {
  display         : flex;
  justify-content : center;
  margin-top      : 24px;
  gap             : 26px;

  &--more-mt {
    margin-top : 40px;
  }

  &--end {
    justify-content : flex-end;
  }
}

.input-name {
  margin-bottom : 4px;
  font-size     : 12px;
  font-weight   : 600;
}

.input-notification {
  display     : flex;
  align-items : center;
  gap         : 8px;
  margin-top  : 8px;
  font-weight : 500;
  font-size   : 12px;
  color       : var(--text-color2);

  &--error {
    color : #D1513A;
  }

  &__icon {
    display : flex;
    width   : 23px;
  }

  &--center {
    justify-content : center;
  }
}

.save-password {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  margin-top      : 8px;

  .link {
    margin-left : auto;
  }
}

.link {
  display     : flex;
  color       : var(--main-color);
  font-weight : 500;
  font-size   : 14px;
  transition  : 0.2s;

  &--bigger {
    font-size : 16px;
  }

  &--big {
    font-size : 18px;
  }

  &--type2 {
    margin               : 36px 0;
    display              : block;
    font-weight          : 500;
    font-size            : 18px;
    line-height          : 180%;
    text-decoration-line : underline;
    color                : #622582;
    text-align           : center;
  }

  &--decoration {
    text-decoration : underline;
  }

  &--type3 {
    font-weight : 500;
    color       : #622582;
  }

  &--mt16 {
    margin-top : 16px;
  }

  &:hover {
    color : darken(#E41472, 10%);
  }
}

.back-to {
  display         : flex;
  justify-content : center;
  gap             : 16px;
  margin-bottom   : 12px;
  font-weight     : 500;
  font-size       : 18px;
  color           : var(--main-color);

  &__arrow {
    display : flex;
    width   : 32px;
  }
}

.form-logo {
  display : flex;
  width   : 70px;
  margin  : 0 auto 16px;
}

.block-small-title {
  font-weight : 600;
  font-size   : 18px;
  color       : var(--text-color-main);
}

header {
  padding : 15px 0;
  border  : 1px solid #E6E6E6;
}

.header {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
}

.logo {
  display     : flex;
  align-items : center;
  width       : 70px;
}

.header-right {
  display     : flex;
  align-items : center;
  gap         : 16px;
}

.admin {
  display     : flex;
  align-items : flex-start;
  min-height  : 50vh;
  margin-top  : 60px;
  gap         : 60px;

  &__bar {
    flex : 0 0 250px;

    @media screen and (max-width : 991px) {
      width : 100%;
      flex  : 0 0 100%;
    }

    .select {
      &__drop {
        ul {
          > li {
            > button,
            > a {
              padding   : 12px 6px 12px 16px;
              gap       : 16px;
              font-size : 14px;
            }
          }
        }
      }

      &__drop-scroll {
        max-height : none;
      }
    }

    &--small {
      flex-basis : 200px;
      @media screen and (max-width : 991px) {
        flex-basis : 100%;
      }
    }
  }

  &__content {
    flex           : 1 1 auto;
    padding-bottom : 60px;

    @media screen and (max-width : 991px) {
      width : 100%;
      flex  : 0 0 100%;
    }
  }

  @media screen and (max-width : 1199px) {
    gap : 40px;
  }

  @media screen and (max-width : 991px) {
    padding   : 24px 0;
    margin    : 0;
    flex-wrap : wrap;
    gap       : 24px;
  }

  @media screen and (max-width : 767px) {
    min-height : unset;
  }

  &--type2 {
    @media screen and (max-width : 991px) {
      gap : 16px;
    }
  }
}

.sidebar-nav-icon {
  display : block;
  width   : 24px;
  height  : 24px;

  svg,
  img {
    object-fit : contain;
    width      : 100%;
    height     : 100%;
  }
}

.table {
  @media screen and (max-width : 1280px) {
    .td-name {
      font-size : 14px;
    }
    .td-value {
      font-size : 14px;
    }
  }
  @media screen and (max-width : 1199px) {
    .td-name {
      font-size : 16px;
    }
    .td-value {
      font-size : 16px;
    }
  }

  @media screen and (max-width : 767px) {
    .td-value {
      font-size : 16px;
    }
  }

  &--second-type {
    padding : 0;
    border  : none;

    .table-header {
      border-bottom  : 2px solid var(--main-color);
      padding-bottom : 8px;
    }

    .td {
      padding : 0;

      &:nth-child(2) {
        @media screen and (max-width : 1199px) {
          //flex-wrap: wrap;
        }
      }
    }

    .tr {
      & + .tr {
        margin-top : 30px;

        @media screen and (max-width : 199px) {
          margin-top : 40px;
        }
      }

      @media screen and (max-width : 1199px) {
        padding-top : 24px;
        border-top  : 2px solid var(--main-color) !important;
      }
    }
  }

  &--border-top {
    .table-header {
      padding-top   : 28px;
      border-bottom : none;
      border-top    : 2px solid var(--main-color);
    }
  }

  &--property-management {
    .tr {
      align-items           : center;
      grid-template-columns : 55px 1.7fr 0.8fr 0.6fr 1.35fr 0.8fr 0.8fr 1.1fr 1fr minmax(91px, 0.8fr);
      gap                   : 8px;

      .input {
        max-width     : 200px;
        margin-bottom : 0;
      }
    }

    .td {
      &:nth-child(2) {
        word-break : normal;
      }
    }

    .td-value,
    .td-name,
    .select__current,
    .input-item {
      font-size : 14px;
    }
  }

  &--orders-management {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.61fr 1.95fr 1.55fr 1.51fr 1.4fr 0.9fr 0.65fr;
    }
  }

  &--user-management {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.9fr 1.64fr 1.74fr 0.8fr 1.6fr 1fr 1fr 0.96fr;
    }
  }

  &--user-account {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.8fr 1.5fr 1.2fr 1.4fr 1fr 1.4fr;
    }
  }

  &--order-history {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.4fr 1.92fr 1.54fr 0.45fr 0.72fr 1.01fr 0.4fr 0.8fr 0.76fr 0.9fr;
    }
  }

  &--dividends {
    .tr {
      gap                   : 8px;
      grid-template-columns : 2fr 2fr 1fr 1fr;
    }
  }

  &--transactions {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.7fr 0.9fr 1fr 1fr 1fr 0.9fr;
    }
  }

  &--transactions-history {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 0.6fr 1.45fr 0.45fr 0.7fr 0.45fr 0.75fr 1.2fr 0.9fr 1.2fr;
    }
  }

  &--transactions-history-fiat {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 1.45fr 0.8fr 1.45fr 1.45fr 0.4fr 0.9fr 0.9fr;
    }
  }

  &--transactions-history-withdrawal {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 1.45fr 0.8fr 1.45fr 1.45fr 0.4fr 0.9fr 0.9fr;
    }
  }

  &--transactions-history-apartment {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.3fr 2fr 0.6fr 1.45fr 1.2fr 0.6fr 0.9fr;
    }
  }

  &--transactions-history-convert {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 0.9fr 1.3fr 0.8fr 0.5fr 0.5fr 1fr 1.3fr 1fr;
    }
  }

  &--platform-tokens {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.5fr 1.5fr 1.5fr 1fr;
    }

    .td {
      @media screen and (max-width : 767px) {
        word-break : normal;
      }
    }

    .input {
      max-width : 200px;
    }
  }

  &--fee-management {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr 0.6fr;
    }
  }

  &--fee-dividends,
  &--dividends-history {
    .td {
      @media screen and (max-width : 767px) {
        word-break : normal;
      }

      &:nth-child(3) {
        word-break : normal;
      }

      &:nth-child(4) {
        @media screen and (max-width : 767px) {
          flex-wrap : wrap;
        }
      }
    }
  }

  &--fee-dividends {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.2fr 1fr 1fr 1.5fr;
    }
  }

  &--dividends-history {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.3fr 1fr 1.2fr 1.3fr;
    }
  }

  &--limitations {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1fr minmax(265px, 2fr) minmax(265px, 1.8fr) 0.5fr;
    }

    .td {
      @media screen and (max-width : 767px) {
        word-break : normal;
        flex-wrap  : nowrap !important;
      }
    }
  }

  &--pending-withdraw-crypto,
  &--pending-withdraw-fiat {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.15fr 0.66fr 0.86fr 1.54fr 0.76fr 0.44fr 1.4fr minmax(132px, 1.22fr);
    }
  }

  &--pending-withdraw-crypto-manual {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1fr 1.4fr 1.2fr 1fr minmax(132px, 1fr);
    }
  }

  &--subscribed-mailing {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1fr 1fr;
    }
  }

  &--investors {
    .tr {
      gap                   : 8px;
      grid-template-columns : 25px 1.4fr 1fr 1fr;
    }
  }

  &--interest {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.45fr 0.75fr 0.75fr 0.6fr 0.65fr 1.2fr;
    }
  }
}

.table--fee-dividends.table--second-type {
  .tr + .tr {
    margin-top : 20px;
  }
}

.table-buttons {
  justify-content : flex-end;

  button {
    max-width : 25px;
    min-width : 25px;
    flex      : 0 0 0 25px;
    height    : 25px;

    & + button {
      margin-left : 8px;
    }
  }

  &--center {
    justify-content : center;
  }

  &--bigger {
    button {
      max-width : 40px;
      min-width : 40px;
      flex      : 0 0 40px;
      height    : 40px;

      svg {
        width : 100%;
      }
    }
  }

  &--text-btns {
    button {
      max-width   : unset;
      height      : auto;
      flex        : unset;
      width       : auto;
      font-weight : 500;
      font-size   : 16px;
      @media screen and (max-width : 1280px) {
        font-size : 14px;
      }
      @media screen and (max-width : 767px) {
        font-size : 16px;
      }
    }
  }

  &__text-confirm {
    color : #25A353;
  }
}

.table-filter {
  margin-bottom   : 36px;
  display         : flex;
  justify-content : space-between;

  @media screen and (max-width : 767px) {
    flex-direction : column;
    gap            : 16px;
  }
}

.td {
  &--center {
    text-align : center;
  }

  &--right {
    .td-edit-wrapper {
      justify-content : flex-end;
    }
  }
}

.td-value {
  a {
    text-decoration : underline;
  }

  &--fw-600 {
    font-weight : 600;
  }

  &--blue-link {
    a {
      color : #3D58DB;
    }
  }
}

.dashboard {
  margin                : 0 auto;
  width                 : 820px;
  max-width             : 100%;
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 16px;

  &--standart {
    margin : 0;
    width  : auto;
  }

  &--info0 {
    .dashboard-item__info {
      margin : 0;
    }
  }

  &--mb40 {
    margin-bottom : 40px;
  }

  @media screen and (max-width : 620px) {
    grid-template-columns : repeat(1, 1fr);
    gap                   : 24px;
  }
}

.dashboard-item {
  position      : relative;
  padding       : 24px;
  display       : flex;
  align-items   : center;
  gap           : 22px;
  background    : #F3F3F3;
  border-radius : 15px;

  &__info {
    margin-bottom : 8px;

    p {
      font-weight : 500;
      font-size   : 14px;
      line-height : 180%;
      color       : #25252E;
    }

    span {
      font-weight : 600;
      font-size   : 20px;
      line-height : 180%;
      color       : #25252E;
    }

    &--mb0 {
      margin-bottom : 0;
    }
  }

  &--fixed-size1 {
    max-width : 477px;
  }

  &--bigger {
    width     : 420px;
    max-width : 100%;
  }

  &--full-width {
    width : 100%;
  }

  &--flex-start {
    align-items : flex-start;
  }

  &--small-pad {
    padding : 17px 24px;
  }

  &--type2 {
    padding : 12px 24px;
    width   : auto;
  }

  &__buttons {
    position       : absolute;
    top            : 50%;
    right          : 24px;
    transform      : translateY(-50%);
    display        : flex;
    flex-direction : column;
    align-items    : flex-end;

    button {
      display     : block;
      font-weight : 600;
      font-size   : 14px;
      color       : #E41472;
      text-align  : right;

      &:first-child {
        margin-bottom : 10px;
        @media screen and (max-width : 400px) {
          margin-bottom : 0;
        }
      }
    }

    @media screen and (max-width : 800px) {
      position  : relative;
      top       : 0;
      right     : 0;
      transform : none;
    }
    @media screen and (max-width : 400px) {
      width          : 100%;
      flex-direction : row;
      gap            : 16px;
    }
  }

  @media screen and (max-width : 800px) {
    padding : 16px;
    gap     : 12px;
  }
  @media screen and (max-width : 400px) {
    flex-wrap : wrap;
  }
}

.margin-bottom-4 {
  margin-bottom : 4px;
}

.new-property {
  &__title {
    margin-bottom : 40px;
    font-weight   : 600;
    font-size     : 34px;
    line-height   : 150%;
    color         : #25252E;
    @media screen and (max-width : 1199px) {
      margin-bottom : 24px;
    }
  }
}

.realty-photos-gallery {
  margin                : 40px 0;
  display               : grid;
  grid-template-columns : repeat(4, 1fr);
  gap                   : 20px;

  .controls-group {
    height             : 202px;
    display            : grid;
    grid-template-rows : repeat(2, 1fr);
    gap                : 8px;

    .upload-file-box-custom {
      height : 100%;
    }

    .upload-file {
      padding : 0;
    }
  }

  .rearrange-images {
    width            : 99%;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    color            : #9E9E9E;
    font-size        : 16px;
    font-weight      : 500;
    background-image : url('data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%23D5D5D5FF\' stroke-width=\'2\' stroke-dasharray=\'7\' stroke-dashoffset=\'28\' stroke-linecap=\'square\'/%3e%3c/svg%3e');
    border-radius    : 8px;
  }

  @media screen and (max-width : 991px) {
    grid-template-columns : repeat(2, 1fr);
    gap                   : 3px;
    .realty-photo--height-type5 {
      height : 202px;

      img {
        border-radius : 10px;
      }
    }
  }
  @media screen and (max-width : 767px) {
    grid-template-columns : repeat(2, 1fr);
    gap                   : 3px;
    .realty-photo--height-type5 {
      height : 164px;
    }
  }

  &--always-display {
    display : grid;

    @media screen and (max-width : 767px) {
      display : grid;
    }
  }
}

.realty-photo-block {
  position      : relative;
  display       : flex;
  flex-wrap     : wrap;
  gap           : 16px;
  border-radius : 10px;
  overflow-y    : hidden;
  @media screen and (max-width : 767px) {
    gap : 3px;
  }
}

.upload-action {
  width           : 32px;
  position        : absolute;
  display         : flex;
  align-items     : center;
  text-align      : center;
  background      : rgba(0, 0, 0, 0.4);
  backdrop-filter : blur(4px);
  padding         : 5px;
  bottom          : 0;
  transform       : none;
  flex-direction  : row;
  border-radius   : 10px 0 0 0;
  right           : 0;
  justify-content : center;
  transition      : background 0.2s;
  height          : 32px;

  &:hover {
    background : rgba(255, 0, 0, 0.8);
  }

  @media screen and (max-width : 767px) {
    font-size : 14px;
  }

  &__icon {
    width : 16px;

    svg {
      display : block;
    }
  }

  &__hide-text {
    @media screen and (max-width : 767px) {
      display : none;
    }
  }
}

.new-property-info {
  margin-bottom : 40px;
  @media screen and (max-width : 1180px) {
    flex-direction : column;
    > div {
      width : 100%;
    }
  }

  .realty-photos-gallery {
    @media screen and (max-width : 991px) {
      gap : 10px;
    }
  }
}

.property-input-grid {
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 16px;
  @media screen and (max-width : 991px) {
    grid-template-columns : repeat(1, 1fr);
  }

  &--type2 {
    margin-top : 40px;
  }
}

.property-input {
  width : 420px;
}

.property-textarea {
  .textarea textarea {
    min-height : 190px;
  }
}

.new-property-date {
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 40px 190px;
  max-width             : 810px;
  @media screen and (max-width : 620px) {
    grid-template-columns : repeat(1, 1fr);
  }
}

.new-property-date-item {
  &__title {
    margin-bottom : 16px;
    font-weight   : 500;
    font-size     : 24px;
    line-height   : 150%;
    @media screen and (max-width : 620px) {
      text-align : center;
    }
  }

  &__contetn {
    @media screen and (max-width : 620px) {
      text-align : center;
    }
  }
}

.new-property-address-grid {
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : repeat(1, 1fr);
    gap                   : 18px;
  }

  .input {
    margin-bottom : 0;
  }
}

.property-dividends-grid {
  margin-bottom         : 40px;
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : repeat(1, 1fr);
    gap                   : 18px;
  }

  .input {
    margin-bottom : 0;
  }
}

.select--type3 {
  height : 60px;
}

.category-title {
  margin-top      : 48px;
  margin-bottom   : 36px;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  gap             : 16px;
  font-weight     : 500;
  font-size       : 24px;
  line-height     : 150%;
  @media screen and (max-width : 767px) {
    flex-wrap : wrap;
  }

  &--type2 {
    margin-bottom : 16px;
  }
  small {
    font-size: 60%;
    color:var(--text-color3)
  }
}

.property-choose-real {
  .select {
    max-width : 220px;
  }
}

.new-property-dividends {
  .radio {
    margin-bottom : 30px;
  }
}

.dividends-select {
  &__title {
    margin-bottom : 16px;
    font-weight   : 500;
    font-size     : 18px;
  }
}

.legal-documents-list {
  display        : flex;
  flex-direction : column;
  gap            : 8px;

  &__link {
    display     : flex;
    align-items : center;
    gap         : 8px;
    font-weight : 600;
    font-size   : 16px;
    color       : #25252E;
  }
}

.legal-documents-list-item {
  display               : grid;
  align-items           : center;
  grid-template-columns : 1.2fr 0.8fr 1fr;
  gap                   : 16px;

  @media screen and (max-width : 1180px) {
    grid-template-columns : repeat(1, 1fr);
    gap                   : 12px;
    & + & {
      margin-top : 25px;
    }

    .radio {
      margin-bottom : 0;

      & + .radio {
      }
    }
    .radio--position-right {
      justify-content : flex-start;
    }
  }
}

.legal-documents-button {
  margin          : 34px 0;
  display         : flex;
  justify-content : center;
  gap             : 16px;

  &--type2 {
    justify-content : flex-end;
  }

  @media screen and (max-width : 1199px) {
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr;
  }
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr 1fr;
  }
  @media screen and (max-width : 475px) {
    grid-template-columns : 1fr;
  }
}

.radio--position-right {
  display         : flex;
  justify-content : flex-end;
}

.input-name {
  margin-bottom : 8px;
  font-weight   : 500;
  font-size     : 16px;
  line-height   : 180%;

  &--bigger {
    font-size : 18px;
  }
}

.popup-alert {
  padding : 90px 0;
  @media screen and (max-width : 750px) {
    padding : 40px 0;
  }
}

.popup-title {
  margin-bottom : 24px;
  font-weight   : 600;
  font-size     : 24px;
  line-height   : 150%;
  text-align    : center;

  &--small {
    width  : 250px;
    margin : 0 auto;
  }

  &--center {
    margin-right : auto;
    margin-left  : auto;
    text-align   : center;
  }

  &--mb16 {
    margin-bottom : 16px;
  }

  &--bigger {
    font-weight : 600;
    font-size   : 34px;
  }

  @media screen and (max-width : 560px) {
    font-size : 20px;
  }
}

.add-document-pop {
  margin : 0 auto;
}

.add-document-pop-upload {
  display     : flex;
  align-items : center;
  gap         : 16px;

  &__info {
    padding       : 8px 51px;
    display       : flex;
    align-items   : center;
    gap           : 10px;
    font-weight   : 500;
    font-size     : 16px;
    line-height   : 1;
    color         : #E41472;
    background    : #FFF;
    border        : 2px solid #E41472;
    border-radius : 14px;
  }
}

.table-title {
  margin-bottom : 28px;
  font-weight   : 600;
  font-size     : 26px;

  &--mb40 {
    margin-bottom : 40px;
  }

  &--mb50 {
    margin-bottom : 50px;
  }

  &--type-2 {
    margin-top    : 40px;
    margin-bottom : 12px;
  }

  &--m0 {
    margin : 0;
  }

  &--pt10 {
    padding-top : 10px;
  }

  &--mb18 {
    margin-bottom : 18px;
  }

  &--fz34 {
    font-size : 34px;
  }
}

.order-manegement-request-title {
  margin-bottom : 32px;
  font-weight   : 600;
  font-size     : 26px;
  line-height   : 150%;
  text-align    : center;
}

.sell-form {
  max-width : 664px;
  margin    : 38px auto 0 auto;

  .form-group {
    & + .form-group {
      margin-top : 40px;
    }
  }
}

.form-group {
  margin-top : 16px;
  position   : relative;
}

.block-step {
  position    : absolute;
  display     : flex;
  align-items : center;
  top         : 5px;
  left        : -100px;
  font-weight : 700;
  font-size   : 18px;
  gap         : 16px;

  &__icon {
    display : flex;
    width   : 24px;
  }

  @media screen and (max-width : 1024px) {
    position      : relative;
    top           : 0;
    left          : 0;
    margin-bottom : 16px;
  }
}

.upload-gallery {
  display               : grid;
  grid-template-columns : repeat(3, 1fr);
  gap                   : 30px;

  @media screen and (max-width : 767px) {
    grid-template-columns : repeat(2, 1fr);
  }
}

.uploaded-item {
  // display: flex;
  &--type2 {
    .upload-file__icon {
      svg {
        path {
          stroke : #25252E;
        }
      }

      &--grey {
        svg {
          path {
            stroke : #9E9E9E;
          }
        }
      }
    }

    .uploaded-item__title {
      color : #9E9E9E;
    }
  }
}

.uploaded-item-text {
  margin-top  : 4px;
  font-weight : 500;
  font-size   : 12px;
  line-height : 22px;

  &--red {
    color : #D1513A;
  }
}

.uploaded-photo {
  position : relative;
  display  : flex;
  height   : 202px;
  width    : 100%;

  @media screen and (max-width : 767px) {
    height : 164px;
  }

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 8px;
  }
}

.form-item-title {
  margin-top    : 64px;
  margin-bottom : 40px;
  font-weight   : 600;
  font-size     : 20px;
  line-height   : 180%;
}

.gallery-container {
  max-width : 932px;
  margin    : 0 auto;
}

.realty-photos-gallery--type2 {
  grid-template-columns : repeat(3, 1fr);

  .realty-photo-block--two-third {
    grid-row    : span 1;
    grid-column : span 2;
  }
}

.realty-photo {
  display : flex;
  height  : 244px;
  width   : 100%;

  @media screen and (max-width : 991px) {
    height : 192px;
  }

  img {
    width      : 100%;
    height     : 100%;
    object-fit : cover;

    @media screen and (max-width : 991px) {
      border-radius : 0px;
    }
  }

  &--height-type2 {
    height : 503px;

    @media screen and (max-width : 991px) {
      height : 400px;
    }
  }

  &--height-type3 {
    height : 616px;
    @media screen and (max-width : 991px) {
      height : 250px;
    }
  }

  &--height-type4 {
    height : 300px;
    @media screen and (max-width : 991px) {
      height : 123px;
    }
  }

  &--height-type5 {
    height : 202px;
    @media screen and (max-width : 991px) {
      height : 164px;
    }
  }
}

.button-save-all {
  display     : flex;
  align-items : center;
  gap         : 12px;
  font-weight : 600;
  font-size   : 24px;
  color       : #E41472;
}

.slider-header {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  height          : 46px;
  width           : 1310px;
  max-width       : 100%;
  margin          : 60px auto 0 auto;

  &__close {
    display         : flex;
    align-items     : center;
    justify-content : center;
    gap             : 8px;
    width           : 100px;
    height          : 45px;
    background      : rgba(37, 37, 46, 0.5);
    border-radius   : 14px;
    font-weight     : 500;
    font-size       : 16px;
    color           : #FFF;

    svg {
      width : 16px;
    }
  }

  &__count {
    font-size : 20px;
    color     : #FFF;

    @media screen and (max-width : 1280px) {
      font-size : 16px;
    }
  }

  .realty-actions {
    min-width : 75px;
    gap       : 0 40px;
  }

  .object-action {
    width           : 32px;
    padding         : 0;
    background      : none;
    backdrop-filter : none;

    svg {
      width : 100%;
    }

    .fill path {
      fill : #FFF;
    }

    .stroke path {
      stroke : #FFF;
    }
  }

  @media screen and (max-width : 1280px) {
    position   : absolute;
    top        : 0;
    left       : 0;
    margin-top : 30px;
    padding    : 0 16px;
  }
}

.photo-slider-box {
  position        : relative;
  display         : flex;
  justify-content : center;
  align-items     : center;
  gap             : 0 80px;
  width           : 100%;
  margin-top      : 40px;

  @media screen and (max-width : 1280px) {
    margin-top : 100px;
    gap        : 0 40px;
  }

  @media screen and (max-width : 767px) {
    width : calc(100% + 20px);

    .slider-arrow {
      display : none;
    }
  }
}

.slider-arrow {
  display         : flex;
  justify-content : center;
  align-items     : center;
  height          : 60px;
  flex            : 0 0 60px;
  border-radius   : 50%;
  background      : rgba(37, 37, 46, 0.5);

  @media screen and (max-width : 1280px) {
    flex   : 0 0 40px;
    height : 40px;

    svg {
      width : 12px;
    }
  }

  svg {
    width : 15px;
  }

  &:disabled {
    background : rgba(37, 37, 46, 0.3);

    svg {
      opacity : 0.2;
    }
  }

  &--prev {
    svg {
      margin-left : -4px;
      transform   : rotate(180deg);
    }
  }

  &--next {
    svg {
      margin-right : -4px;
    }
  }
}

.photo-slide {
  display         : flex;
  width           : 100%;
  max-width       : 1000px;
  margin          : 0 auto;
  justify-content : center;

  img {
    width         : auto;
    height        : auto;
    max-width     : 100%;
    border-radius : 15px;

    @media screen and (max-width : 767px) {
      border-radius : 0;
    }
  }
}

.nav-drop {
  border-top : 1px solid #D9D9D9;
  display    : none;

  &.active {
    display : block;
  }

  &--type2 {
    border : none !important;

    .nav-drop__drop-item > ul {
      > li {
        border        : none !important;
        border-radius : 0 !important;
        line-height   : 1.8;

        > button,
        > a {
          line-height   : 1.8;
          border-radius : 0 !important;
          padding-left  : 55px;
        }
      }
    }
  }
}

.info-title {
  margin-top    : 40px;
  margin-bottom : 24px;
  font-weight   : 600;
  font-size     : 20px;
  line-height   : 180%;
  color         : #25252E;

  &--left {
    transform : translateX(-100px);
    @media screen and (max-width : 1180px) {
      transform : translateX(0px);
    }
  }

  &--mt0 {
    margin-top : 0;
  }
}

.popup-success-icon {
  margin-bottom : 24px;
  display       : flex;
  width         : 60px;
  height        : 60px;
  margin        : 0 auto 24px auto;

  svg {
    width : 100%;
  }

  &--small {
    width  : 20px;
    height : 20px;
  }
}

.back-link {
  margin-bottom : 24px;
  display       : flex;
  align-items   : center;
  gap           : 8px;
  font-weight   : 400;
  font-size     : 14px;
  color         : var(--text-color-main);

  &__arrow {
    display : flex;
    width   : 25px;

    svg path {
      fill : var(--text-color-main);
    }
  }

  &--mb20 {
    margin-bottom : 20px;
  }

  &--mb16 {
    margin-bottom : 16px;
  }
}

.account {
  display   : flex;
  flex-wrap : wrap;
  gap       : 60px;

  &__left {
    flex : 1 1 0;
    @media screen and (max-width : 991px) {
      flex : 0 0 100%;
    }
  }

  &__right {
    width  : 368px;
    margin : 0 auto;
    @media screen and (max-width : 991px) {
      width : 700px;
    }
    @media screen and (max-width : 767px) {
      width : 100%;
    }
  }

  @media screen and (max-width : 991px) {
    gap : 24px;
  }
}

.new-user {
  display        : flex;
  flex-direction : column;
  gap            : 24px;

  &__title {
    font-weight : 500;
    font-size   : 24px;
    color       : #E41472;
  }

  &__date {
    font-weight : 500;
    font-size   : 18px;
    color       : #9E9E9E;
  }

  &__wallet {
    position      : relative;
    padding       : 24px 24px 24px 90px;
    background    : #F3F3F3;
    border-radius : 15px;
  }

  &__wallet-icon {
    position : absolute;
    top      : 24px;
    left     : 24px;
    width    : 45px;
  }

  &__wallet-title {
    font-weight : 500;
    font-size   : 14px;
    color       : var(--text-color-main);
  }

  &__wallet-number {
    margin-top  : 4px;
    font-weight : 600;
    font-size   : 20px;
    color       : var(--text-color-main);
  }

  &__actions-group {
    display         : flex;
    justify-content : center;
    gap             : 24px;
  }

  &__actions-title {
    margin-bottom : 8px;
    text-align    : center;
    font-weight   : 500;
    font-size     : 18px;
    color         : #9E9E9E;

    &--mb20 {
      margin-bottom : 20px;
    }
  }
}

.user-form {
  position      : relative;
  padding       : 24px;
  background    : #F3F3F3;
  border-radius : 15px;

  &__body {
    margin-top : 24px;
  }

  .input-item {
    background : transparent;
  }
}

.user-checkbox {
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  gap             : 12px;

  &__status {
    font-weight : 500;
    font-size   : 20px;
    color       : #C51918;
  }

  &--black {
    .checkbox-item {
      border-color : #000;

      &::before {
        border-color : #000;
      }
    }

    input:checked ~ .checkbox-item {
      border-color : var(--main-color);

      &::before {
        border-color : var(--main-color);
      }
    }
  }
}

.checkbox-item {
  position      : relative;
  display       : block;
  width         : 55px;
  height        : 35px;
  border        : 5px solid #C51918;
  border-radius : 50px;
  cursor        : pointer;
  transition    : all 0.3s ease;

  &::before {
    content       : '';
    position      : absolute;
    top           : 50%;
    left          : 2px;
    transform     : translateY(-50%);
    width         : 8px;
    height        : 8px;
    border        : 5px solid #C51918;
    border-radius : 50%;
    transition    : all 0.3s ease;
  }

  &--small {
    width  : 36px;
    height : 23px;
    border : 3px solid #C51918;

    &::before {
      content : '';
      width   : 5px;
      height  : 5px;
      border  : 3px solid #C51918;
    }
  }
}

input:checked ~ .checkbox-item {
  border-color : #25A353;

  &::before {
    left         : 23px;
    border-color : #25A353;
  }
}

input:checked ~ .checkbox-item--small {
  &::before {
    left : 16px;
  }
}

.popup-window--doc .popup-window__inside {
  justify-content : flex-start;
  padding         : 0 0 10px 0;
}

.doc-panel-box {
  width         : 100%;
  background    : #D9D9D9;
  border-radius : 15px;
}

.doc-panel {
  display     : flex;
  align-items : center;
  padding     : 24px 0;
  gap         : 13px;

  &__back {
    display         : flex;
    justify-content : center;
    margin-right    : 22px;
    width           : 22px;
  }

  &__title {
    font-size   : 20px;
    font-weight : 600;
  }

  &__action {
    display     : flex;
    width       : 36px;
    margin-left : auto;
  }
}

.table-top-wrapper {
  display         : flex;
  justify-content : space-between;
  align-items     : flex-start;
  flex-wrap       : wrap;
  gap             : 16px;
  margin-bottom   : 40px;

  &--center {
    align-items : center;
  }

  &--end {
    align-items : flex-end;
  }

  &--mb24 {
    margin-bottom : 24px;
  }

  @media screen and (max-width : 767px) {
    flex-direction : column;
    align-items    : flex-start;
    margin-bottom  : 24px;
    .switcher {
      order         : -2;
      margin-top    : -10px;
      margin-bottom : 16px;
      width         : 100%;
      max-width     : 100%;
    }
  }

  &--type2 {
    justify-content : flex-start;
  }
}

.calendar-input {
  width : 258px;
}

.td-wrapper {
  display : flex;
  gap     : 8px;

  button {
    font-weight : 500;
    font-size   : 16px;
    color       : #E41472;
    @media screen and (max-width : 1280px) {
      font-size : 12px;
    }
    @media screen and (max-width : 767px) {
      font-size : 16px;
    }
  }
}

.sidebar-arrow {
  display     : flex;
  width       : 24px;
  margin-left : auto;
}

.table-header-hide {
  display       : none;
  flex-wrap     : wrap;
  margin-bottom : 24px;
  gap           : 16px;
  @media screen and (max-width : 1199px) {
    display : flex;
  }
}

.transaction-switchers {
  display       : flex;
  flex-wrap     : wrap;
  gap           : 16px;
  margin-bottom : 40px;
}

.switcher {
  display       : flex;
  padding       : 8px;
  width         : 100%;
  min-height    : 64px;
  background    : #F3F3F3;
  border-radius : 14px;

  &--3items {
    max-width : 428px;
  }

  &--wider {
    max-width : 500px;
  }

  &--4items {
    max-width : 600px;
  }

  &--2items,
  &--smallest {
    max-width : 354px;
  }

  &--fixed-size,
  &--smaller {
    width     : 368px;
    max-width : 100%;
  }

  &__item {
    // width: 100%;
    flex          : 1 1 auto;
    width         : 100%;
    border-radius : 7px;
    font-weight   : 600;
    font-size     : 16px;
    @media screen and (max-width : 800px) {
      font-size : 14px;
    }

    &.active {
      background : #FFF;
      color      : #E41472;
    }
  }
}

.admin-profile {
  display   : flex;
  flex-wrap : wrap;
  gap       : 60px;

  &__left,
  &__right {
    display        : flex;
    flex-direction : column;
    align-items    : flex-end;
  }

  &__left {
    flex : 1 1 477px;
  }

  &__right {
    flex : 1 1 433px;
  }
}

.autofication-2fa {
  max-width : 542px;

  &__description {
    margin-bottom : 24px;
    font-weight   : 400;
    font-size     : 18px;
    color         : #9E9E9E;
  }

  &__item {
    margin-bottom   : 16px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    font-weight     : 500;
    font-size       : 18px;
    color           : #25252E;
    line-height     : 180%;
  }
}

.dasboard-wrapper {
  display       : flex;
  flex-wrap     : wrap;
  gap           : 16px;
  margin-bottom : 40px;
  @media screen and (max-width : 991px) {
    margin-bottom : 24px;
  }

  .dashboard-item {
    width : calc(50% - 8px);

    &__icon {
      display         : flex;
      justify-content : center;
      width           : 60px;
      @media screen and (max-width : 800px) {
        width      : 40px;
        max-height : 40px;
      }
    }
  }

  @media screen and (max-width : 1199px) {
  }
  @media screen and (max-width : 1199px) {
    .dashboard-item {
      width : 100%;
    }
  }
}

.upload-file {
  &__icon {
    width : 24px;
  }
}

.attachmen-text {
  padding-top   : 5px;
  margin-bottom : 8px;
  font-weight   : 500;
  font-size     : 16px;
  line-height   : 180%;
}

.doc-frame {
  max-width  : 1240px;
  margin-top : 78px;
}

.green {
  color : #25A353;
}

.yellow {
  color : #CA8000;
}

.orange {
  color : #D1513A;
}

.red {
  color : #E41472;
}

.error-red,
.red2 {
  color : #C51918;
}

.grey {
  color : #9E9E9E;
}

.profile-header {
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;
  flex-wrap       : wrap;
  margin-bottom   : 40px;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    flex-direction : column;
    align-items    : flex-start !important;
    .switcher {
      order         : -2;
      margin-top    : -24px;
      margin-bottom : 24px;
      width         : 100%;
    }
  }

  .token-to-invest {
    justify-content : flex-start;
    width           : 350px;
    max-width       : 100%;
    margin-top      : 16px;
    padding         : 24px;

    &__icon {
      width : 60px;
    }

    @media screen and (max-width : 767px) {
      width : 100%;
    }
  }

  &__group {
    max-width : 592px;
    @media screen and (max-width : 767px) {
      max-width : 100%;
      width     : 100%;
    }
  }

  &--type2 {
    margin-bottom : 24px;
    @media screen and (max-width : 767px) {
    }
  }

  &--type3 {
    margin-bottom : 10px;
  }

  &--align-end {
    align-items : flex-end;
  }

  .block-subtitle {
    margin-top : 8px;
    text-align : left;
  }
}

.tfa-wrapper {
  max-width : 542px;

  &--w594 {
    max-width : 594px;
  }
}

.tfa-transaction-fee {
  display       : flex;
  align-items   : center;
  gap           : 24px;
  margin-bottom : 16px;

  &__name {
    font-weight : 500;
    font-size   : 18px;
    line-height : 180%;
    color       : var(--text-color-main);
  }

  &__number {
    padding-top : 3px;
    font-weight : 500;
    font-size   : 16px;
    line-height : 180%;
    color       : var(--text-color2);
  }
}

.tfa-options {
  &__name {
    margin-bottom : 16px;
    font-weight   : 500;
    font-size     : 18px;
    color         : var(--text-color-main);
  }
}

.tfa-buttons {
  display         : flex;
  align-items     : center;
  justify-content : flex-end;
  gap             : 16px;

  &--mt40 {
    margin-top : 40px;
  }
}

.copy-input-btn {
  position    : absolute;
  top         : 50%;
  right       : 16px;
  transform   : translateY(-50%);
  font-weight : 500;
  font-size   : 16px;
  color       : #E41472;
}

.qr-box {
  display       : flex;
  width         : 135px;
  margin-bottom : 24px;

  &--center {
    margin-left  : auto;
    margin-right : auto;
  }

  &--mb0 {
    margin-bottom : 0;
  }
}

.button-wrapper {
  display         : flex;
  justify-content : center;
}

.input-token-name {
  position    : absolute;
  top         : 50%;
  right       : 16px;
  transform   : translateY(-50%);
  font-weight : 500;
  font-size   : 16px;
  color       : var(--text-color2);
}

.order-option {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  margin-bottom   : 16px;

  &__name {
    font-weight : 500;
    font-size   : 16px;
    line-height : 180%;
    color       : var(--text-color2);
  }

  &__value {
    font-weight : 500;
    font-size   : 16px;
    line-height : 180%;
    color       : var(--text-color-main);
  }

  @media screen and (max-width : 767px) {
    margin-bottom : 12px;
  }

  &--mob-column {
    @media screen and (max-width : 767px) {
      display : block;
    }
  }
}

.td-edit-wrapper {
  display     : flex;
  align-items : center;
  gap         : 8px;

  &--gap16 {
    gap : 16px;
  }

  &--distribute {
    @media screen and (max-width : 767px) {
      width : 100%;
      .input {
        width : 100%;
      }
    }
  }
}

.td-edit-button {
  display : flex;
  width   : 25px;
}

.calendar-button {
  display       : flex;
  align-items   : center;
  gap           : 8px;
  font-weight   : 500;
  font-size     : 16px;
  padding       : 8px 16px;
  border        : 1px solid #D5D5D5;
  border-radius : 14px;

  &__icon {
    display : flex;
    width   : 24px;
  }
}

.tfa-info {
  margin-bottom : 24px;

  &__title {
    margin-bottom : 8px;
    font-weight   : 600;
    font-size     : 20px;
    line-height   : 180%;
    color         : #25252E;
  }

  &__description {
    font-weight : 400;
    font-size   : 18px;
    line-height : 32px;
    color       : #9E9E9E;
  }
}

.switcher-wrapper {
  margin-bottom   : 40px;
  display         : flex;
  justify-content : space-between;
  gap             : 16px;
  @media screen and (max-width : 1315px) {
    flex-wrap : wrap;
  }
  @media screen and (max-width : 635px) {
    .switcher--mob-column,
    .switcher:first-child {
      flex-direction : column;
      max-width      : 100%;

      .switcher__item {
        min-height : 50px;
      }
    }
  }
}

.input-buttons-wrapper {
  position    : absolute;
  right       : 16px;
  top         : 50%;
  transform   : translateY(-50%);
  display     : flex;
  align-items : center;
  gap         : 8px;

  .show-pass {
    position  : static;
    transform : translate(0);
  }
}

.input-btn-status {
  display : flex;
  width   : 24px;
}

.phone-block {
  display       : flex;
  gap           : 16px;
  align-items   : flex-end;
  margin-bottom : 24px;

  .input {
    margin-bottom : 0;
    width         : auto;
  }

  @media screen and (max-width : 500px) {
    flex-wrap : wrap;
  }
}

.email-change-block {
  width       : 272px;
  margin      : 0 auto;
  padding-top : 270px;
}

.email-change-info {
  display        : flex;
  flex-direction : column;
  align-items    : center;
  gap            : 24px;
}

.email-change-icon {
  display : flex;
  width   : 24px;
}

.email-change-title {
  font-weight : 600;
  font-size   : 24px;
  line-height : 150%;
  color       : var(--text-color-main);
}

.email-change-text {
  font-weight : 500;
  font-size   : 16px;
  line-height : 180%;
  color       : #9E9E9E;
}

.item-loader {
  margin-top : 4px;

  &__size-text {
    font-weight : 400;
    font-size   : 12px;
    line-height : 22px;
    color       : var(--text-color-main);
  }

  &__upload-text {
    margin-bottom : 10px;
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    color         : #E41472;
  }

  &__item {
    position      : relative;
    width         : 100%;
    height        : 4px;
    background    : #FCE1ED;
    border-radius : 5px;

    span {
      position      : absolute;
      top           : 0;
      left          : 0;
      display       : block;
      height        : 100%;
      background    : #E41472;
      border-radius : 5px;
    }
  }
}

.td-payment {
  display     : flex;
  align-items : center;
  gap         : 10px;
}

.load-btn {
  display : flex;
  width   : 20px;

  svg path {
    fill : #3D58DB;
  }
}

.input-blocks {
  display : flex;
  gap     : 16px;
}

.table-property-photo {
  display : flex;
  width   : 50px;
  height  : 40px;

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 5px;
  }
}

/*--Real estate preview--*/

.card-slider-box {
  position : relative;
}

.card-slider-item {
  display       : flex;
  height        : 276px;
  border-radius : 15px;

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 15px;
  }

  @media screen and (max-width : 500px) {
    height : 313px;
  }
}

.realty-photos {
  margin-top : 14px;

  .realty-photos-gallery {
    display   : flex;
    flex-wrap : wrap;
    margin    : 0 -8px;
    gap       : 16px 0;
    @media screen and (max-width : 767px) {
      display : none;
    }
  }

  .realty-photo-block {
    display       : flex;
    flex-wrap     : wrap;
    gap           : 16px;
    padding       : 0 8px;
    border-radius : 5px;
    overflow      : hidden;

    &--half {
      width : 50%;
    }

    &--two-row {
      .realty-photo {
        width : calc(50% - 8px);
      }
    }
  }
}

.all-photos {
  position : absolute;
  bottom   : 24px;
  left     : 32px;
  width    : 194px;
  display  : flex;
  z-index  : 5;

  &__btn {
    background : #FFF;
    border     : 1px solid var(--border-color-main);
    color      : var(--text-color-main);

    &:hover {
      background : #FFF;
      color      : var(--main-color);

      .fill path {
        fill : var(--main-color);
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  position        : absolute;
  top             : 50%;
  transform       : translateY(-50%);
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 30px;
  height          : 30px;
  margin          : 0;
  background      : rgba(255, 255, 255, 0.8) !important;
  border-radius   : 50%;
  z-index         : 3;
  transition      : 0.2s;

  svg {
    width : 10px;
  }

  &:hover {
    background : rgba(255, 255, 255, 0.95) !important;
  }

  &.swiper-button-disabled {
    opacity : 0;
  }
}

.swiper-button-next {
  right : 23px;
}

.swiper-button-prev {
  left : 23px;

  svg {
    transform : rotate(180deg);
  }
}

.object-label {
  display       : inline-flex;
  align-items   : center;
  height        : 30px;
  gap           : 10px;
  border-radius : 15px;
  padding       : 0 16px;
  font-weight   : 600;

  &__dot {
    display       : block;
    width         : 10px;
    height        : 10px;
    border-radius : 50%;
  }

  &--live {
    background : #31BDAA;
    color      : #FFF;

    .object-label__dot {
      background : #FFF;
    }
  }

  &--soon {
    background : #D2EC26;
    color      : var(--text-color-main);

    .object-label__dot {
      background : var(--text-color-main);
    }
  }

  &--sold-out {
    background : #706D6D;
    color      : #FFF;

    .object-label__dot {
      background : #FFF;
    }
  }

  &--at-card {
    position : absolute;
    top      : 24px;
    left     : 23px;
    z-index  : 3;
  }
}

.swiper-pagination-bullet {
  width      : 10px;
  height     : 10px;
  background : #C6C6C6;
  opacity    : 1 !important;
}

.swiper-pagination-bullet-active {
  background : #FFF !important;
}

.real-estate-section {
  padding : 28px 0 60px 0;
  @media screen and (max-width : 767px) {
    padding : 17px 0 21px 0;
  }
}

.realty-wrapper {
  max-width : 1100px;
  margin    : 0 auto;

  .info-title {
    font-weight : 700;
  }
}

.back-btn-block {
  margin-bottom : 16px;
}

.back-btn {
  display     : flex;
  align-items : center;
  font-size   : 14px;
  line-height : 1.8;
  transition  : all 0.2s;

  &__arrow {
    display      : flex;
    flex         : 0 0 15px;
    min-width    : 15px;
    margin-right : 12px;
  }

  &:hover {
    color : var(--main-color);
  }
}

.realty-header {
  display         : flex;
  justify-content : space-between;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    flex-direction : column;
    margin-top     : 18px;
  }
}

.realty-name {
  font-weight : 700;
  font-size   : 24px;
  line-height : 180%;
  @media screen and (max-width : 991px) {
    font-size : 22px;
  }
  @media screen and (max-width : 767px) {
    font-size : 20px;
  }
}

.realty-address {
  display     : flex;
  align-items : center;
  gap         : 12px;
  color       : var(--text-color-main);
  font-weight : 400;
  font-size   : 18px;
  line-height : 180%;
  @media screen and (max-width : 767px) {
    font-size : 16px;
  }

  &__icon {
    display    : flex;
    min-width  : 16px;
    max-height : 20px;
  }
}

.realty-actions {
  display         : flex;
  align-items     : center;
  justify-content : flex-end;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    justify-content : flex-start;
  }
}

.realty-date {
  display         : flex;
  justify-content : flex-end;
  gap             : 10px;
  text-align      : right;
  margin-top      : 16px;
  font-size       : 16px;
  line-height     : 180%;
  @media screen and (max-width : 767px) {
    justify-content : flex-start;
  }

  span {
    font-weight : 600;
  }
}

.show-mob {
  display : none;
  @media screen and (max-width : 767px) {
    display : block;
  }
}

.hide-mob {
  display : block;
  @media screen and (max-width : 767px) {
    display : none;
  }
}

.add-to-fav,
.object-action {
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 30px;
  height          : 30px;
  background      : rgba(37, 37, 46, 0.15);
  backdrop-filter : blur(10px);
  border-radius   : 8px;
  z-index         : 3;

  svg {
    width : 16px;
  }

  .stroke path {
    stroke     : #FFF;
    transition : 0.2s;
  }

  &--type2 {
    svg {
      width : 18px;
    }

    background : var(--button-bg2);

    .stroke path {
      stroke     : var(--text-color-main);
      transition : 0.2s;
    }
  }

  &:hover,
  &.active {
    .stroke path {
      stroke : var(--main-color);
    }
  }

  &--at-card {
    position : absolute;
    top      : 24px;
    right    : 23px;
  }
}

.realty-block {
  margin-top : 40px;

  &__content {
    margin-top : 24px;
  }

  &__header {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    flex-wrap       : wrap;
    gap             : 16px;

    &--type2 {
      border-bottom  : 1px solid var(--main-color);
      padding-bottom : 26px;
    }

    .button--type4 {
      border-width : 2px;
    }

    @media screen and (max-width : 767px) {
      flex-direction : column;
      align-items    : flex-start;
    }
  }

  &--type2 {
    border-top  : 1px solid var(--border-color2);
    padding-top : 40px;
  }

  .info-title {
    margin : 0;
  }
}

.realty-info-box {
  display               : grid;
  grid-template-columns : 1fr 1fr;
  gap                   : 16px;
  @media screen and (max-width : 991px) {
    grid-template-columns : 1fr 1fr;
  }
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }

  &__item {
    padding       : 24px 16px;
    border        : 1px solid #CFCFCF;
    border-radius : 15px;

    &--third,
    &:nth-child(2) {
      .realty-info + .realty-info {
        margin-top : 20px;
      }
    }

    &:first-child {
      @media screen and (max-width : 767px) {
        .realty-info {
          flex-wrap : wrap;

          &__value {
            width        : 100%;
            padding-left : 36px;
          }
        }
      }
    }
  }
}

.realty-info {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  gap             : 0 12px;
  line-height     : 180%;

  &__name {
    display     : flex;
    align-items : center;
    gap         : 12px;
    max-width   : 180px;
    font-weight : 500;
    @media screen and (max-width : 767px) {
      max-width : 100%;
    }

    &--short {
      max-width : 150px;
    }
  }

  &__name-icon {
    display : flex;
    flex    : 0 0 24px;
  }

  &__value {
    font-weight : 600;
    font-size   : 18px;
  }

  & + .realty-info {
    margin-top : 8px;
  }
}

.realty-overview {
  &__text {
    max-width   : 900px;
    font-weight : 400;
    font-size   : 16px;
    line-height : 180%;

    p {
      margin-bottom : 16px;
    }

    h4 {
      margin-bottom : 8px;
      font-weight   : 600;
    }
  }

  &__text-hidden {
    display : none;
  }

  &__more {
    margin-top : 16px;
  }

  &.active {
    .realty-overview__text-hidden {
      display : block;
    }
  }
}

.realty-map {
  margin-top : 40px;
  max-width  : 100%;
  height     : 397px;

  img,
  iframe {
    width      : 100%;
    height     : 100%;
    object-fit : cover;
  }

  @media screen and (max-width : 767px) {
    width       : calc(100% + 30px);
    margin-left : -15px;
    max-width   : unset;
  }
}

.realty-invest {
  position    : relative;
  //top: 2px;
  margin-top  : -50px;
  width       : 303px;
  margin-left : auto;

  &__btn {
    display         : flex;
    justify-content : center;
    @media screen and (max-width : 767px) {
      .button {
        max-width : 160px;
      }
    }

    &--type2 {
      @media screen and (max-width : 767px) {
        margin : 0 auto;
        width  : 100%;
        .button {
          max-width : unset;
          width     : 100%;
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    position        : fixed;
    bottom          : 0;
    left            : 0;
    top             : unset;
    display         : flex;
    height          : 81px;
    align-items     : center;
    justify-content : space-between;
    width           : 100%;
    background      : #FFF;
    padding         : 16px;
    box-shadow      : 0px -5px 10px rgba(0, 0, 0, 0.05);
    z-index         : 30;
  }

  &--type2 {
    @media screen and (max-width : 767px) {
      justify-content : center;
    }
  }
}

.token-to-invest {
  display         : flex;
  align-items     : center;
  justify-content : center;
  gap             : 21px;
  padding         : 20px 55px;
  background      : var(--block-bg3);
  border-radius   : 15px;
  line-height     : 180%;

  & + .realty-invest__btn {
    margin-top : 21px;
    @media screen and (max-width : 767px) {
      margin-top : 0;
    }
  }

  @media screen and (max-width : 991px) {
    padding : 20px;
  }

  &__icon {
    display : flex;
    width   : 45px;
    @media screen and (max-width : 767px) {
      display : none;
    }
  }

  &__value {
    margin-top  : 4px;
    font-weight : 600;
    font-size   : 20px;
  }

  @media screen and (max-width : 767px) {
    padding    : 0;
    background : none;
  }

  &__title {
    @media screen and (max-width : 767px) {
      font-size : 11px;
    }
  }

  &__value {
    @media screen and (max-width : 767px) {
      margin-top : 0;
      font-size  : 14px;
    }
  }

  &--pc-view-only {
    @media screen and (max-width : 767px) {
      background : var(--block-bg3);
      .token-to-invest__icon {
        display : flex;
      }
      .token-to-invest__title {
        font-size : 14px;
      }
      .token-to-invest__value {
        font-size : 20px;
      }
    }
  }
}

.realty-features-box {
  display : flex;
}

.features-list {
  display     : flex;
  align-items : flex-start;
  flex-wrap   : wrap;
  gap         : 16px 31px;

  &__group {
    display               : grid;
    grid-template-columns : 1.3fr 1fr;
    align-items           : center;
    max-width             : 443px;
    gap                   : 16px 40px;
    @media screen and (max-width : 575px) {
      max-width             : 100%;
      grid-template-columns : 1fr;
      .realty-features:nth-child(3) {
        order : 4;
      }
    }

    &--second {
      .realty-features__info {
        @media screen and (max-width : 767px) {
          gap : 4px 16px;
        }
      }
    }
  }
}

.realty-features {
  display     : flex;
  align-items : flex-start;
  gap         : 16px;

  &__icon {
    display : flex;
    flex    : 0 0 26px;
  }

  &__info {
    display     : flex;
    align-items : center;
    flex-wrap   : wrap;
    gap         : 8px 16px;
  }

  &__name {
    &--mob {
      display : none;
    }
  }

  &__value {
    font-weight : 600;
    font-size   : 16px;

    &--full {
      width : 100%;
    }

    &--extra {
      color : var(--main-color);
    }
  }

  & + .realty-features {
  }
}

.realty-doc-list {
  display        : flex;
  flex-direction : column;
  gap            : 26px;
}

.realty-doc {
  display     : flex;
  align-items : center;
  gap         : 12px;

  &__icon {
    display    : flex;
    min-width  : 16px;
    max-height : 20px;
  }

  a {
    font-weight     : 500;
    font-size       : 16px;
    line-height     : 180%;
    text-decoration : underline;
    color           : #3D58DB;

    &:hover {
      text-decoration : none;
    }
  }
}

/*--END Real estate preview--*/

.invest-object {
  display : flex;
  gap     : 16px 24px;
  margin  : 32px 0;
  @media screen and (max-width : 767px) {
    flex-wrap : wrap;
  }

  &__photo {
    display : flex;
    width   : 140px;

    img {
      width         : 100%;
      height        : 140px;
      object-fit    : cover;
      border-radius : 15px;
    }
  }

  &__info {
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
    flex            : 1;
    gap             : 8px;
    @media screen and (max-width : 767px) {
      flex : 0 0 100%;
    }
  }

  &__info-list {
    display : flex;
    gap     : 12px;
    @media screen and (max-width : 767px) {
      display               : grid;
      grid-template-columns : 1fr 1fr;
    }
    @media screen and (max-width : 425px) {
      grid-template-columns : 1fr;
    }
  }

  .link {
    font-size   : 18px;
    line-height : 1.8;
  }
}

.loader-container {
  display         : flex;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  min-height      : 300px;
}

.lds-default {
  display  : inline-block;
  position : relative;
  width    : 80px;
  height   : 80px;
}

.lds-default div {
  position      : absolute;
  width         : 6px;
  height        : 6px;
  background    : #E41472;
  border-radius : 50%;
  animation     : lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay : 0s;
  top             : 37px;
  left            : 66px;
}

.lds-default div:nth-child(2) {
  animation-delay : -0.1s;
  top             : 22px;
  left            : 62px;
}

.lds-default div:nth-child(3) {
  animation-delay : -0.2s;
  top             : 11px;
  left            : 52px;
}

.lds-default div:nth-child(4) {
  animation-delay : -0.3s;
  top             : 7px;
  left            : 37px;
}

.lds-default div:nth-child(5) {
  animation-delay : -0.4s;
  top             : 11px;
  left            : 22px;
}

.lds-default div:nth-child(6) {
  animation-delay : -0.5s;
  top             : 22px;
  left            : 11px;
}

.lds-default div:nth-child(7) {
  animation-delay : -0.6s;
  top             : 37px;
  left            : 7px;
}

.lds-default div:nth-child(8) {
  animation-delay : -0.7s;
  top             : 52px;
  left            : 11px;
}

.lds-default div:nth-child(9) {
  animation-delay : -0.8s;
  top             : 62px;
  left            : 22px;
}

.lds-default div:nth-child(10) {
  animation-delay : -0.9s;
  top             : 66px;
  left            : 37px;
}

.lds-default div:nth-child(11) {
  animation-delay : -1s;
  top             : 62px;
  left            : 52px;
}

.lds-default div:nth-child(12) {
  animation-delay : -1.1s;
  top             : 52px;
  left            : 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform : scale(1);
  }
  50% {
    transform : scale(2);
  }
}

@import 'ui-modifiers.scss';


.gallery-container {
  margin: 0 64px;
  max-width: 100%;
  .realty-photo--height-type4 {
    height : auto;
    aspect-ratio: 1/1;
  }
}
.realty-photos-gallery--type2 {
  grid-template-columns: repeat(6, 1fr);
}