input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.button {
  gap: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: var(--main-color);

  &:hover {
    background: darken(#e41472, 10%);
  }

  &--authorization {
    font-weight: 500;
  }

  &--type2 {
    background: transparent;
    border: 1px solid var(--border-color-main);
    color: var(--text-color-main);

    &:hover {
      background: transparent;
      border-color: var(--main-color);
    }
  }

  &--type3 {
    background: var(--accent-third-color);

    &:hover {
      background: var(--accent-secondary-color);
    }
  }

  &--type4 {
    background: #fff;
    border: 2px solid var(--main-color);
    color: var(--main-color);

    .fill path {
      fill: var(--main-color);
    }

    &:hover {
      background: var(--main-color);
      color: #fff;

      .fill path {
        fill: #fff;
      }
    }
  }

  &--type5 {
    background: #fff;
    border: 1px solid var(--main-color);
    color: var(--text-color-main);

    .fill path {
      fill: var(--main-color);
    }

    &:hover {
      background: #fff;
      color: var(--main-color);

      .fill path {
        fill: #fff;
      }
    }
  }

  &--type6 {
    background: var(--button-bg2);
    color: var(--text-color-main);

    &:hover {
      background: var(--button-bg2);
      color: var(--main-color);
    }
  }

  &--type7 {
    background: #f3f3f3;
    color: #25252e;

    &:hover {
      background: #dddddd;
      color: #25252e;
    }
  }

  &:disabled {
    background: #b6b6b6;
    opacity: 1;
    color: #fff;

    &:hover {
      background: #b6b6b6;
    }
  }

  &--width-auto {
    width: auto;
    min-width: auto;
  }
  &--w125 {
    width: 125px;
  }

  &--small {
    width: 150px;
  }

  &--long {
    width: 275px;
  }

  &--medium {
    width: 200px;
  }

  &--mob-full-width {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  &--smaller-height {
    height: 38px;
  }

  &--width-180 {
    width: 180px;
  }

  &--big-height {
    height: 60px;
  }

  &--action-type {
    width: 45px;
    height: 45px;
    min-width: unset;
    padding: 0 8px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--fs-14 {
    font-size: 14px;
  }
}

.popup-link {
  display: block;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #e41472;
  text-align: center;
}

.btn-icon {
  display: flex;
  width: 21px;

  .fill path,
  .stroke path {
    transition: 0.2s;
  }

  &--width-24 {
    width: 24px;
  }
}

.input-icon {
  width: 24px;

  &--calendar {
    width: 24px;
  }

  .stroke-type path {
    stroke: var(--text-color2);
  }

  &--color {
    .fill path {
      fill: var(--main-color);
    }
  }
}

.input {
  margin-bottom: 16px;

  &--no-mb {
    margin-bottom: 0;
  }

  &--error {
    .input-item {
      border-color: #d1513a;
    }
  }
  &--mt40 {
    margin-top: 40px;
  }
  &--mb24 {
    margin-bottom: 24px;
  }
  &--small {
    width: 160px;
  }
  &--big-width {
    max-width: 500px;
    width: 100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &--search {
    max-width: 380px; 
    width:  100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &--limitations {
    width: 100%;
    max-width: 265px;
  }
}

.input-item {
  height: 60px;
  border: 1px solid var(--border-color-main);
  border-radius: 14px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-main-color);

  @include input-placeholder {
    color: var(--text-secondary-color);
    transition: all 0.15s ease;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color2);
  }

  &:focus {
    border-color: #25a353;
    border-width: 2px;
  }

  &--small-height {
    height: 45px;
    font-size: 14px;
    @include input-placeholder {
      font-size: 14px;
    }
  }
  &--fs-16 {
    font-size: 16px;
    @include input-placeholder {
      font-size: 16px;
    }
  }

  &--left-icon {
    padding-left: 50px;
  }
  &--right-icon {
    padding-right: 60px;
  }
  &--right-icon2 {
    padding-right: 50px;
  }

  &--code-select {
    padding-left: 135px;
  }

  &--td {
    height: 45px;
    padding: 0 11px;
  }

  &--pr-125 {
    padding-right: 125px;

    @media screen and (max-width: 767px) {
      padding-right: 115px;
    }
  }
}

.flag-icon {
  display: flex;
  width: 28px;
}

.textarea {
  border: 1px solid var(--border-color-main);
  border-radius: 14px;
  padding: 10px 16px;

  textarea {
    min-height: 100px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-main-color);
    resize: none !important;

    @include input-placeholder {
      color: var(--text-secondary-color);
      transition: all 0.15s ease;
      font-size: 16px;
      font-weight: 500;
      color: var(--text-color2);
    }

    @media screen and (max-width: 767px) {
      min-height: 130px;
    }
  }
}

.show-pass {
  width: 24px;

  svg path {
    fill: var(--text-color-main);
  }
}

.input-action,
.input-icon-text,
.input-coin {
  position: absolute;
  right: 16px;
  left: unset;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 500;
  color: var(--main-color);

  &--right-75 {
    right: 75px;
  }
}

.input-icon-text {
  right: 35px;
  margin-right: 10px;
}

.input-coin {
  color: var(--text-color2);
}

.select {
  min-width: 166px;

  height: 45px;
  border-color: var(--border-color-main);
  border-radius: 14px;

  &__current {
    justify-content: flex-start;
    gap: 10px;
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: var(--text-color-main);
    &--placeholder {
      color: var(--text-color2);
    }
  }

  &__current-arrow {
    margin-left: auto;
    flex: 0 0 12px;
    min-width: 12px;
  }

  &__drop-scroll {
    max-height: 360px;
  }

  &__drop {
    display: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 0;

    ul {
      > li {
        display: flex;
        flex-direction: column;

        &.active {
          background: #f8f8f8;
        }

        > a,
        > button {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 12px 16px;
          font-weight: 500;
          font-size: 14px;
        }

        & + li {
          border-top: 1px solid #d9d9d9;
        }

        &:first-child {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          > a,
          > button {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }

        &:last-child,
        &:only-child {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          > a,
          > button {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }

      &.sub-drop {
        display: none;
        padding-left: 55px;
        padding-bottom: 10px;

        &.active {
          display: block;
        }

        > li {
          border: none;

          > a,
          > button {
            padding-left: 0;
            padding-right: 0;
            border-radius: 0 !important;
            transition: 0.2s;

            &:hover {
              background: transparent;
              color: var(--main-color);
            }
          }
        }
      }
    }
  }

  &--user {
    .select__drop-scroll {
      max-height: unset;
    }
  }

  &--phone {
    min-width: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    border: none;
    .select__current {
      padding: 0;
      border: none;
    }
    .select__drop {
      width: 105px;
      top: calc(100% + 16px);
      left: -16px;
    }

    .select__drop-scroll {
      max-height: 295px;
    }
  }

  &--sidebar-nav {
    min-width: auto;
    height: auto;
    border: none;

    @media screen and (max-width: 767px) {
      height: 60px;
      border: 1px solid var(--border-color-main);
    }

    .select__current {
      display: none;

      @media screen and (max-width: 767px) {
        display: flex;
      }
    }

    .select__drop {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      box-shadow: none;

      @media screen and (max-width: 767px) {
        display: none;
        position: absolute;
        top: calc(100% + 16px);
      }

      ul > li {
        &.active {
          @media screen and (max-width: 767px) {
            background: transparent;
          }
        }

        > a,
        > button {
          &:hover {
            background: #f8f8f8;
          }
        }
      }
    }
  }

  &--td-filter {
    min-width: auto;
    display: inline-flex;
    height: auto;
    border: none;
    .select__current {
      width: auto;
      padding: 0;
    }
    .select__drop {
      min-width: 120px;
      right: 0;
      left: unset;

      @media screen and (max-width: 767px) {
        left: 0;
        right: unset;
      }
    }
  }

  &--type2 {
    border-color: var(--main-color);
  }

  &--select-inside {
    &.active {
      > .select__drop {
        display: block !important;
      }
    }

    .select {
      min-width: auto;

      &__drop {
        padding: 8px;
        display: none !important;
      }
      &.active {
        > .select__drop {
          display: block !important;
        }
      }
    }

    ul {
      li {
        border: none;
        .select {
          border: none;
        }
      }
    }
  }
  &--big {
    height: 60px;
  }
  &__current {
    &--red {
      color: #c51918;
    }
  }
  &__current-arrow {
    &--w25 {
      min-width: 25px;
    }
  }

  &--table-select {
    min-width: 120px;
    border: none;
    height: auto;
    .select__current-arrow {
      margin-left: 0;
    }
    .select__current {
      width: auto;
      font-weight: 600;
      padding: 0;
      &--right {
        justify-content: flex-end;
      }
    }
  }
  &--wide-drop {
    .select__drop {
      width: 160px;
    }
  }
  &--mob-right-drop {
    @media screen and (max-width: 767px) {
      .select__drop {
        left: unset;
        right: 0;
      }
    }
  }
}

.select-icon {
  display: flex;
  width: 22px;

  .fill path {
    fill: #9e9e9e;
  }

  .stroke path {
    stroke: #9e9e9e;
  }

  &--dark {
    .fill path {
      fill: var(--text-color-main);
    }

    .stroke path {
      stroke: var(--text-color-main);
    }
  }

  &--color {
    .fill path {
      fill: var(--main-color);
    }

    .stroke path {
      stroke: var(--main-color);
    }
  }

  &--arrow {
    margin-left: auto;
    flex: 0 0 12px;
    min-width: 12px;
  }
}

.block-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 1.5;

  &--mr-auto {
    margin-right: auto;
  }

  &--smaller {
    font-size: 24px;
  }

  @media screen and (max-width: 1199px) {
    //font-size: 24px;
  }

  @media screen and (max-width: 991px) {
    //font-size: 24px;
  }

  @media screen and (max-width: 767px) {
    //font-size: 20px;
  }

  &--mob-16 {
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.block-subtitle {
  font-size: 18px;
  line-height: 1.78;
  text-align: center;
  color: var(--text-color2);

  a {
    color: #3d58db;
  }

  &--fixed-size {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-title {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 180%;

  &--uppercase {
    text-transform: uppercase;
  }

  @media screen and (max-width: 1199px) {
    font-size: 36px;
  }

  @media screen and (max-width: 991px) {
    font-size: 30px;
  }

  @media screen and (max-width: 767px) {
    font-size: 24px;
    padding: 0 15px;
  }
}

.popup-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  width: 24px;

  svg {
    width: 100%;
  }

  &--type2 {
    transform: none;
    right: 24px;
    top: 24px;
  }
}

.popup-reset {
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  display: flex;
  width: 24px;
}

.popup {
  padding: 50px 24px;

  &--type2 {
    padding: 40px 24px;
  }

  &--smaller {
    width: 431px;
  }

  &--bigger {
    width: 634px;
  }

  &--big {
    width: 700px;
  }
  &--huge {
    width: 807px;
  }
  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &--height-500 {
    min-height: 500px;
    @media screen and (max-width: 775px) {
      min-height: auto;
    }
  }
  &__close {
    width: 24px;
  }
}

.popup-header {
  position: relative;
  min-height: 32px;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: var(--text-four-color);
  }

  & + .popup-body {
    margin-top: 24px;
  }
}

.popup-body {
  margin-top: 0;
}

.popup-footer {
  display: flex;
  gap: 16px;
  margin-top: 18px;

  &--more-mt {
    margin-top: 24px;
  }

  &--mob-column {
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }
}

.popup-text {
  margin: 0;
  font-size: 16px;
  line-height: 165%;

  &--center {
    text-align: center;
  }

  &--mt {
    margin-top: 16px;
  }
  &--mb24 {
    margin-bottom: 24px;
  }
}

.checkbox {
  margin-bottom: 16px;

  &__item {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border: 1.5px solid var(--border-color-main);
    border-radius: 7px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color-main);

    a {
      color: var(--main-color);
      text-decoration: none !important;
    }
  }

  input:checked ~ .checkbox__item {
    border-color: var(--main-color);
    border-width: 2px;
    background: transparent;
  }

  input:checked ~ .checkbox__item .checkbox__item-icon {
    svg path {
      stroke: var(--main-color);
    }
  }
}

.notification-wrapper {
  position: fixed;
  top: 50px;
  right: 32px;

  @media screen and (max-width: 767px) {
    top: 30px;
    right: 16px;
    width: calc(100% - 32px);
  }
}

.notification {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 510px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04),
    0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  &__close {
    top: 24px;
    right: 24px;
    width: 20px;

    button {
      display: flex;
    }
  }

  &__icon {
    width: 20px;
    min-width: 20px;
    margin-top: 2px;

    @media screen and (max-width: 575px) {
      margin-top: 0;
    }

    svg {
      width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color-main);
  }

  &__text {
    max-width: 385px;
    margin-top: 12px;
    line-height: 1.75;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color-main);

    a {
      color: var(--main-color);
    }

    button {
      margin-top: 12px;
      padding: 0 24px;
    }
  }
}

.tooltip-item {
  width: 16px;
  height: 16px;
}

.tooltip {
  @media screen and (max-width: 475px) {
    max-width: 200px;
  }
}

.table {
  border-color: #cfcfcf;
  border-radius: 15px;
  padding: 24px 12px;

  .td {
    word-break: break-all;
    padding: 0 12px;
  }  
}

.table-header {
  line-height: 180%;

  .tr {
    border: none;
  }

  .select__current {
    font-weight: 600;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.td--right {
  .select--td-filter {
    justify-content: flex-end;
  }
}

.td-name {
  font-weight: 600;
  font-size: 16px;
}

.td-value {
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;

  &--medium {
    font-size: 16px;
    font-weight: 500;
  }
  &--size2 {
    font-size: 16px;
  }
  &--fw600 {
    font-weight: 600;
  }
}

.td-hidden-name {
  min-width: 100px;
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color-main);

  @media screen and (max-width: 1199px) {
    display: flex;
  }
}

.table-body {
  margin-top: 27px;
  line-height: 180%;

  @media screen and (max-width: 1199px) {
    margin-top: 0;
  }

  .tr {
    border: none;

    & + .tr {
      margin-top: 16px;
    }
  }

  .tr {
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .td {
    @media screen and (max-width: 1199px) {
      width: calc(50% - 4px);
      &--center, &--right{
        text-align: left;
        justify-content: flex-start;
        .td-edit-wrapper, .table-buttons {
          justify-content: flex-start;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 4px;
      word-break: normal;
      & + .td {
        margin-top: 8px;
      }
      .td-value, .td-edit-wrapper, .table-buttons{
        text-align: right;
        justify-content: flex-end;
      }
      
    }
    
  }
}



.popup-window {
  z-index: 200;
  background: rgba(37, 37, 46, 0.8);

  &__inside {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  &--doc {
    .popup-window__inside {
      justify-content: flex-start;
      padding: 0 0 10px 0;
    }
  }
}

.upload-file {
  &__title {
    font-size: 16px;
    color: var(--text-color-main);
    @media screen and (max-width: 475px) {
      font-size: 14px;
    }
  }

  &__text {
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color2);

    @media screen and (max-width: 475px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 16px;
  }

  &--grey {
    .upload-file__title {
      color: #9e9e9e;
    }
    .upload-file__icon {
      svg {
        path {
          stroke: #9e9e9e;
        }
      }
    }
  }
}

.upload-file-box {
  width: 100%;
  height: 202px;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D5D5D5FF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='28' stroke-linecap='square'/%3e%3c/svg%3e");

  border-radius: 8px;
  @media screen and (max-width: 767px) {
    height: 164px;
  }
  &--pink-border {
    background-image: none;
    border: 2px solid #fac3dc;
  }
  &--no-border {
    background-image: none;
  }
  &--red-border {
    background-image: url("data:image/svg+xml,%3Csvg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='201' height='201' rx='7.5' stroke='%23D1513A' stroke-dasharray='6 6'/%3E%3C/svg%3E%0A");
  }
  &--disabled {
    .upload-file__icon {
      svg {
        path {
          stroke: #9e9e9e;
        }
      }
    }
    .upload-file__title {
      color: #9e9e9e;
    }
  }
}

.switch {
  &__toggler {
    width: 40px;
    height: 24px;
    border-radius: 12px;
    border: 3px solid #000000;

    &::before {
      width: 8px;
      height: 8px;
      background: transparent !important;
      border: 3px solid #000;
    }
  }

  input:checked ~ .switch__toggler {
    border-color: var(--main-color);

    &:before {
      left: 18px;
      border-color: var(--main-color);
    }
  }
}

.pagination-block {
  margin-top: 40px;
}

.pagination {
  gap: 10px 30px;
  margin: 0;

  @media screen and (max-width: 767px) {
    gap: 10px 25px;
  }

  > li {
    display: flex;
    padding: 0;

    > a,
    > button {
      margin-top: 0;
      height: auto;
      width: auto;
      min-width: unset;
      background: none !important;
      border: none !important;
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-main);
    }

    &.arrow {
      .stroke path {
        stroke: var(--text-color-main);
      }

      &.disabled {
        svg {
          opacity: 1;
        }

        .stroke path {
          stroke: #d9d9d9;
        }
      }
    }

    &:not(.disabled) {
      &:hover,
      &.active {
        > a,
        > button {
          background: none;
          color: var(--main-color);
        }

        .stroke path {
          stroke: var(--main-color);
        }
      }
    }
  }
}

.radio {
  &__label {
    gap: 22px;
    &--smaller {
      gap: 16px;
    }
  }
  &__item {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    &::after {
      display: none;
    }
  }
  &__text {
    margin-left: 0;
    font-weight: 500;
    font-size: 18px;
  }
}

.select-block {
  &__name {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color-main);
  }
}

.popup-title {
  a {
    color: #3d58db;
  }
}

.input-search-button {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  width: 35px;
}
