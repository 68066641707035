.table-body-custom .td .input {
  margin-bottom: 0;
}

.table-body-custom .tr {
  grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr;
}

.td-value-center {
  text-transform: uppercase;
}