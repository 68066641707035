.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  display: none;
}

.new-property-date-item__contetn div .react-datepicker {
  box-shadow: none;
}

.react-datepicker__month-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.react-datepicker__year-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.react-datepicker__header__dropdown--select {
  margin-top: -10px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #000000;
}

.react-datepicker__navigation--previous {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #EBEBF0;
  margin-left: 19px;
}

.react-datepicker__navigation-icon .react-datepicker__navigation-icon--previous {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 19px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color:#000;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -6px;
}

.react-datepicker__navigation--next {
  border-radius: 50%;
  background: #EBEBF0;
}

.react-datepicker__header {
  border-bottom: none;
}

.react-datepicker__day-names {
  padding: 20px 0 0 0;
  text-transform: uppercase;
}

.react-datepicker__day-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  opacity: 0.5;
}

.react-datepicker__navigation--next {
  margin-right: 20px;
}

// .new-property-date-item__contetn div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week

.react-datepicker__day {
  line-height: 31px;
  width: 32px;
}

.react-datepicker__navigation-icon {
  top: 5px;
}

.react-datepicker__navigation-icon--next {
  left: 0;
}

.new-property .new-property-date {
  @media screen and (max-width: 1098px) {
    grid-gap: 0;
    gap: 0;
  }
}

.react-datepicker {
  margin: 0 auto;

  @media screen and (min-width: 621px) {
    margin: 0;
  }
}

.select {
  cursor: pointer;

  &__drop--active {
    display: block;
    cursor: pointer;
    width: max-content;
  }
}

.imput-file-custom {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  opacity: 0;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

.button--container {
  position: relative;
}

.property-dividends-grid .input .input-wrapper .input-item {
  width: 100%;
  color: #9e9e9e;
  text-align: start;
}

.input-wrapper .react-datepicker-wrapper {
  width: 100%;
}

.upload-action--background {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition-property: width, height;
  transition-duration: .2s;
}

.upload-action--background:hover {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.1);
  transition-property: width, height, background-color;
  transition-duration: 1s;
}


.realty-photo-block-max-heigth {
  max-height: 202px;
}

.uploaded-item--type2 {
  position: relative;
}

.file-uploader {
  position: absolute;
  opacity: 0;
}

.table--property-management .tr-custom {
  grid-template-columns: 1.8fr 1.6fr .6fr 1.3fr .8fr 1.1fr 1fr .8fr;
}

.table-filter .table-filter-left .select-custom {
  min-width: 181px;
  z-index: unset;
}

.table .table-header-hide .select {
  z-index: unset;
}

.table-buttons--custom {
  gap: 10px;
}

.select--td-filter-custom {
  min-width: 144px;
}

.td-name-custom-start {
  text-align: start;
}
.block-position-calendar-custom {
  position: absolute;
}

.admin-section-position {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  background: #fff;
}

.map-container {
  width: 100%;
  height: 100%;
}

.main .admin__content .header-all-image-position {
  position: absolute;
  right: 0;
  left: 0;
  top: 87px;
  background: #fff;
  z-index: 3;
  margin-top: 0;
}

.header-all-image-position-body {
  position: absolute;
  right: 0;
  left: 0;
  top: 90px;
  background: #fff;
  z-index: 4;
}

.button-text-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
.property-dividends-grid .input .input-wrapper .input-item-custom {
  color: #000;

  &::placeholder {
    color: #9e9e9e;
  }
}

.input-item-custom-button {
  display: flex;
  align-items: center;
}

.input-item-light-custom {
  color: #9e9e9e;
}

.legal-documents-list__link-custom {
  margin-bottom: 8px;
}

.upload-file-box-custom {
 width: 99%;
}

.uploader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  background-color: rgb(0, 0, 0, 0.8);
}

.realty-info-box-custom {
  grid-template-columns: 1fr 1fr;
}

.realty-overview__text-height {
  height: 93px;
  overflow: hidden;
}

.realty-overview__text-height-full {
  height: 100%;
  overflow: hidden;
  transition: height 0.5s;
}

.td-value-custom {
  padding-left: 4px;
}

.td-value-hyphenation-custom {
  overflow-wrap: break-word;
}

.brdrder-none-custom {
  border: none;

  &:hover {
    border: 2px solid #e41472;
  }
}

.add-document-pop-upload-space {
  justify-content: space-between;
}

.add-document-pop-upload__info-custom {
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  p {
    width: 100%;
    word-break: break-all;
  }
}

.button-width-custom {
  width: 40px;
  height: 46px;

  svg {
    width: 100%;
  }
}

.realty-info-box__item-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.real-estate-section-custom {
  margin-top: -59px;

  @media screen and (max-width: 991px) {
    margin-top: -520px;
    z-index: 27;
    background: inherit;
  }

  @media screen and (max-width: 767px) {
    margin-top: -137px;
  }
}

.realty-wrapper-custom {
  @media screen and (max-width: 767px) {
    padding-bottom: 95px;
  }
}

.link-documents-custom {
  word-break: break-all;
}

.realty-photo-custom {
  cursor: pointer;
}

.select-name-custom {
  color: #9e9e9e;
}

.input-top-custom {
  margin-top: 15px;
}
