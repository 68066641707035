


/*----------------Спец-классы для обнуления, font-weight и остального------------------*/

/*----------------Margin, padding, width-----------------*/

*[class*="--mt-0"]{
    margin-top: 0;
}

*[class*="--mt-5"]{
    margin-top: 5px;
}

*[class*="--mt-10"]{
    margin-top: 10px;
}

*[class*="--mt-15"]{
    margin-top: 15px;
}

*[class*="--mt-20"]{
    margin-top: 20px;
}

*[class*="--mt-25"]{
    margin-top: 25px;
}

*[class*="--mt-30"]{
    margin-top: 30px;
}

*[class*="--mt-35"]{
    margin-top: 35px;
}

*[class*="--mt-40"]{
    margin-top: 40px;
}

*[class*="--mt-45"]{
    margin-top: 45px;
}

*[class*="--mt-50"]{
    margin-top: 50px;
}

*[class*="--mt-4"]{
    margin-top: 4px;
}

*[class*="--mt-8"]{
    margin-top: 8px;
}

*[class*="--mt-12"]{
    margin-top: 12px;
}

*[class*="--mt-16"]{
    margin-top: 16px;
}

*[class*="--mt-24"]{
    margin-top: 24px;
}

*[class*="--mt-32"]{
    margin-top: 32px;
}

*[class*="--mt-48"]{
    margin-top: 48px;
}

*[class*="--mb-0"]{
    margin-bottom: 0;
}

*[class*="--mb-5"]{
    margin-bottom: 5px;
}

*[class*="--mb-15"]{
    margin-bottom: 15px;
}

*[class*="--mb-20"]{
    margin-bottom: 20px;
}

*[class*="--mb-25"]{
    margin-bottom: 25px;
}

*[class*="--mb-30"]{
    margin-bottom: 30px;
}

*[class*="--mb-35"]{
    margin-bottom: 35px;
}

*[class*="--mb-40"]{
    margin-bottom: 40px;
}

*[class*="--mb-45"]{
    margin-bottom: 45px;
}

*[class*="--mb-50"]{
    margin-bottom: 50px;
}

*[class*="--mb-4"]{
    margin-bottom: 4px;
}

*[class*="--mb-8"]{
    margin-bottom: 8px;
}

*[class*="--mb-12"]{
    margin-bottom: 12px;
}

*[class*="--mb-16"]{
    margin-bottom: 16px;
}

*[class*="--mb-24"]{
    margin-bottom: 24px;
}

*[class*="--mb-32"]{
    margin-bottom: 32px;
}

*[class*="--mb-48"]{
    margin-bottom: 48px;
}

*[class*="--ml-0"]{
    margin-left: 0;
}

*[class*="--mr-0"]{
    margin-right: 0;
}

*[class*="--p-0"]{
    padding: 0;
}

*[class*="--pt-0"]{
    padding-top: 0;
}

*[class*="--pb-0"]{
    padding-bottom: 0;
}

*[class*="--pl-0"]{
    padding-left: 0;
}

*[class*="--pr-0"]{
    padding-right: 0;
}

*[class*="--width-100"]{
    width: 100%;
}

*[class*="--width-50"]{
    width: 50%;
}

/*----------------GAPS-----------------*/

*[class*="--gap-5"]{
    gap: 5px;
}

*[class*="--gap-10"]{
    gap: 10px;
}

*[class*="--gap-15"]{
    gap: 15px;
}

*[class*="--gap-20"]{
    gap: 20px;
}

*[class*="--gap-25"]{
    gap: 25px;
}

*[class*="--gap-30"]{
    gap: 30px;
}

*[class*="--gap-35"]{
    gap: 35px;
}

*[class*="--gap-40"]{
    gap: 40px;
}

*[class*="--gap-45"]{
    gap: 45px;
}

*[class*="--gap-50"]{
    gap: 50px;
}

*[class*="--gap-4"]{
    gap: 4px;
}

*[class*="--gap-8"]{
    gap: 8px;
}

*[class*="--gap-12"]{
    gap: 12px;
}

*[class*="--gap-16"]{
    gap: 16px;
}

*[class*="--gap-24"]{
    gap: 24px;
}

*[class*="--gap-32"]{
    gap: 32px;
}

*[class*="--gap-48"]{
    gap: 48px;
}

/*----------------Borders-----------------*/

*[class*="--border-none"]{
    border: none;
}

*[class*="--border-radius-50"]{
    border-radius: 50%;
}

/*----------------Font-weight-----------------*/

*[class*="--fw-300"]{
    font-weight: 300;
}

*[class*="--fw-400"]{
    font-weight: 400;
}

*[class*="--fw-500"]{
    font-weight: 500;
}

*[class*="--fw-600"]{
    font-weight: 600;
}

*[class*="--fw-700"]{
    font-weight: 700;
}

*[class*="--fw-800"]{
    font-weight: 800;
}

*[class*="--fw-900"]{
    font-weight: 900;
}


/*----------------Text-align----------------*/

*[class*="--ta-l"]{ 
    text-align: left; 
} 
 
*[class*="--ta-c"]{ 
    text-align: center; 
} 
 
*[class*="--ta-r"]{ 
    text-align: right; 
}

/*----------------Colors-----------------*/



*[class*="--red"]{
    color: red;
}

*[class*="--green"]{
    color: green;
}

*[class*="--yellow"]{
    color: yellow
}


/*----------------Конец спец-классов------------------*/

