.td-value--width {
  display: block;
}

.input-item-center {
  display: flex;
  align-items: center;
}

.select-z-index {
  z-index: unset;
}

.td-value--medium-upper {
  text-transform: uppercase;
}

.td-value-custom-up {
  &::first-letter {
    text-transform: capitalize;
  }
}

.select__drop--custom {
  z-index: 10;
  width:332px;
  left: unset;
  right: 0;

  @media screen and (max-width: 388px) {
    width:260px;
  }

  @media screen and (max-width: 320px) {
    width:100%;
  }
}

.select--custom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.button-all-loader {
  display: flex;
  align-items: flex-end;
}

.td--custom {
  position: relative;
}

.ul-custom {
  display: flex;
  button {
    justify-content: space-between;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;

    svg {
      min-width: 18px;
      height: 18px;
    }
  }
}

.select__drop-item ul .ul-custom {
  display: flex;
  flex-direction: row;
}

.select__current-text-custom {
  &::first-letter {
    text-transform: uppercase;
  }
}

.select-custom-z-index {
  z-index: unset;
}

.block-subtitle--type2 {
  font-size: 20px;
  color: var(--text-color-main);
  font-weight: 600;
}