.email-change {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 87px);

  &__img {
    margin-bottom: -15px;
  }

  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #25252E;
    margin-bottom: -10px;
  }

  &__subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #9E9E9E;
  }

  &__button {
    background: #E41472;
    border-radius: 14px;
    padding: 8px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #FFFFFF;
    max-width: 140px;
    width: 100%;
  }
}