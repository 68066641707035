.select {
  &__wallet-arrow {
    flex:1 1 auto;
    text-align: end;
  }

  &__small-nav {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.select--sidebar-nav .select__drop--active {
  display: block;
  width: max-content;
}

.select .select__drop--active {
  display: block !important;
  width: max-content;
}