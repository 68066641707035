.react-datepicker {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  border-color: #FFFFFF;
  display: flex;
  padding: 12px 16px;
  gap: 20px;
  flex-wrap: wrap;
  width: min-content;

  &__tab-loop {
    position: absolute;
    display: flex;
  }

  &__header {
    background-color: inherit;
    border-bottom: 0.5px solid #E4E4E4;
    margin-bottom: 10px;
  }

  &__navigation--previous {
    right: 0;
  }

  &__day {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    width: 31px;
    color: #09090D;

    @media (min-width: 365px) {
      line-height: 39px;
      width: 38px;
    }
  }

  &__day-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #09090D;
  }

  &__day-names {
    display: flex;
    justify-content: space-between;
  }

  &__triangle {
    display: none;
  }

  &__month {
    margin: 0;
  }

  &__day-names {
    padding: 9px 0;
  }

  @media (min-width: 685px) {
    flex-wrap: nowrap;
  }
}

.select-year {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #09090D;
  border: none;
  outline: none;
  max-width: 125px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
}

.select-wrapper {
  display: flex;
  gap: 16px;
}

.left-arrow {
  border: 1.5px solid #09090D;
  width: 10px;
  height: 10px;
  border-bottom: none;
  border-right: none;
  background-color: inherit;
  transform: rotate(-45deg);
  margin-right: -4px;
}

.right-arrow {
  border: 1.5px solid #09090D;
  width: 10px;
  height: 10px;
  border-top: none;
  border-left: none;
  background-color: inherit;
  transform: rotate(-45deg);
  margin-left: -5px;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #EBEBF0;
  border-radius: 100%;
}

.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #e41472;
  color: #fff;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(228, 20, 114);
  color: #fff;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgba(228, 20, 114, 0.5);
}

.react-datepicker {
  box-shadow: 0px 0px 12px rgb(96, 97, 112, 0.2), 6px 9px 8px rgb(96, 97, 112, 0.2);
}



